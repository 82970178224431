var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',[_c('material-card',{attrs:{"icon":"mdi-dropbox","icon-small":"","color":"primary","title":"Reports"}},[_c('v-card-text',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-menu',{ref:"fromDateMenu",attrs:{"close-on-content-click":false,"return-value":_vm.fromDate,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.fromDate=$event},"update:return-value":function($event){_vm.fromDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"From date","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.fromDate),callback:function ($$v) {_vm.fromDate=$$v},expression:"fromDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.fromDateMenu),callback:function ($$v) {_vm.fromDateMenu=$$v},expression:"fromDateMenu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.fromDate),callback:function ($$v) {_vm.fromDate=$$v},expression:"fromDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.fromDateMenu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.fromDateMenu.save(_vm.fromDate)}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"11","md":"2"}},[_c('v-menu',{ref:"fromTimeMenu",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.fromTime,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){_vm.fromTime=$event},"update:return-value":function($event){_vm.fromTime=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"From time","prepend-icon":"mdi-clock-time-four-outline","readonly":""},model:{value:(_vm.fromTime),callback:function ($$v) {_vm.fromTime=$$v},expression:"fromTime"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.fromTimeMenu),callback:function ($$v) {_vm.fromTimeMenu=$$v},expression:"fromTimeMenu"}},[(_vm.fromTimeMenu)?_c('v-time-picker',{attrs:{"full-width":"","format":"24hr"},on:{"click:minute":function($event){return _vm.$refs.fromTimeMenu.save(_vm.fromTime)}},model:{value:(_vm.fromTime),callback:function ($$v) {_vm.fromTime=$$v},expression:"fromTime"}}):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-menu',{ref:"toDateMenu",attrs:{"close-on-content-click":false,"return-value":_vm.toDate,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.toDate=$event},"update:return-value":function($event){_vm.toDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"To date","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.toDate),callback:function ($$v) {_vm.toDate=$$v},expression:"toDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.toDateMenu),callback:function ($$v) {_vm.toDateMenu=$$v},expression:"toDateMenu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.toDate),callback:function ($$v) {_vm.toDate=$$v},expression:"toDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.toDateMenu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.toDateMenu.save(_vm.toDate)}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"11","md":"2"}},[_c('v-menu',{ref:"toTimeMenu",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.fromTime,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){_vm.fromTime=$event},"update:return-value":function($event){_vm.fromTime=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"To time","prepend-icon":"mdi-clock-time-four-outline","readonly":""},model:{value:(_vm.toTime),callback:function ($$v) {_vm.toTime=$$v},expression:"toTime"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.toTimeMenu),callback:function ($$v) {_vm.toTimeMenu=$$v},expression:"toTimeMenu"}},[(_vm.toTimeMenu)?_c('v-time-picker',{attrs:{"full-width":"","format":"24hr"},on:{"click:minute":function($event){return _vm.$refs.toTimeMenu.save(_vm.fromTime)}},model:{value:(_vm.toTime),callback:function ($$v) {_vm.toTime=$$v},expression:"toTime"}}):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-autocomplete',{attrs:{"items":_vm.csvList,"item-text":"csvName","item-value":"id","label":"Csv"},model:{value:(_vm.csv),callback:function ($$v) {_vm.csv=$$v},expression:"csv"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-autocomplete',{attrs:{"items":_vm.statusList,"item-text":"statusInText","item-value":"id","label":"Status"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"2"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.generate = !_vm.generate}}},[_vm._v(" Generate ")])],1)],1),_c('v-divider'),_c('BoxReport',{attrs:{"filter":{
            fromDate: _vm.fromDateTime,
            toDate: _vm.toDateTime,
            csv: _vm.csv,
            status: _vm.status,
            statusList: _vm.statusList,
            search: _vm.search,
            generate: _vm.generate,
          }}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }